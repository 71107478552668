import React, { useState } from "react";
import seoAnalysis from "../../assets/images/seo-analysis.png";
import axios from "axios";

const AnalysisForm = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/5f341657-358a-4fed-a084-07bce0b9070d",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Thank you for filling the form!", form);
      })
      .catch((r) => {
        handleServerResponse(
          false,
          "Oops! the connection has been interrupted. Please try to submit again.",
          form
        );
      });
  };

  return (
    <div className="seo-analysis-section ptb-50">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="analysis-img">
              <img src={seoAnalysis} alt="analysis" />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="analysis-form">
              <span className="sub-title">Already have a website ?</span>
              <h2>Test your website and brand to see how it performs.</h2>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}

              <form id="contactForm" onSubmit={handleOnSubmit}>
                <div className="row">
                  <div className="col-lg-6 d-none">
                    <div className="form-group">
                      <input
                        type="text"
                        name="Form Type"
                        className="form-control"
                        id="formtype"
                        value="Analysis form"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="your_name"
                        placeholder="Name"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="your_email"
                        placeholder="Your Email Address"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="website"
                        className="form-control"
                        id="website"
                        required
                        placeholder="Your Website URL"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        id="your_number"
                        required
                        placeholder="Your Phone Number"
                      />
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="default-btn"
                  disabled={serverState.submitting == true ? true : false}
                >
                  <i className="flaticon-tick"></i>
                  {serverState.submitting == true ? "Submitting" : "Check Now"}
                  <span></span>
                </button>

                <div>
                  {serverState.status && (
                    <p
                      className={
                        !serverState.status.ok ? "errorMsg" : "successMsg"
                      }
                    >
                      {serverState.status.msg}
                    </p>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalysisForm;
