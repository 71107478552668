import React from "react";
import ReactWOW from "react-wow";
import { Link } from "gatsby";
import bannerImg2 from "../../assets/images/banner-img2.png";

const Banner = () => {
  return (
    <div className="seo-agency-banner">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="banner-wrapper-content">
              <span className="sub-title">Digital Marketing Agency</span>
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h1>Creative & Strategic Digital Solutions Agency</h1>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInLeft">
                <p>
                  Multi-channel marketing approach focused on{" "}
                  <b>
                    <em>INCREASING CONVERSIONS, REPEAT TRAFFIC</em>
                  </b>{" "}
                  and generating greater{" "}
                  <b>
                    <em>BRAND LOYALTY</em>
                  </b>
                </p>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInRight">
                <div className="btn-box">
                  <Link to="/contact" className="default-btn">
                    <i className="flaticon-right"></i>
                    Get Started
                    <span></span>
                  </Link>
                </div>
              </ReactWOW>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <ReactWOW delay=".1s" animation="fadeInUp">
              <div className="banner-wrapper-image">
                <img src={bannerImg2} alt="banner" />
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
