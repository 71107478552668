import React from "react";
import starIcon from "../../assets/images/zest/small/why-choose-us.svg";
import howitsWork from "../../assets/images/how-its-work.png";

const WhyChooseUs = () => {
  return (
    <section className="how-its-work-area ptb-50 bg-fafafb">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <span className="sub-title2">
                <img src={starIcon} alt="choose" />
                <span>Why Choose Us</span>
              </span>
              <h2>Outstanding Digital Experience</h2>
              <p>
                Our digital marketing team have gained a lot from working with
                hundreds of clients across all industries, helping them sell
                online to international clients all over the world.
              </p>

              <div className="inner-box">
                <div className="single-item">
                  <div className="count-box">1</div>
                  <h3>Innovative and Passionate</h3>
                  <p>
                    Our strong passion for innovation and creativity makes us a
                    brilliant digital marketing and web development partner.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">2</div>
                  <h3>Good Return on Investment</h3>
                  <p>
                    Our professionals leave no stone unturned to ensure good
                    returns from every penny invested in the project.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">3</div>
                  <h3>A Gamut of Services under One Roof</h3>
                  <p>
                    We are an all-encompassing digital marketing firm which has
                    an extensive range of services to offer.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">4</div>
                  <h3>High-Quality Services</h3>
                  <p>
                    We have a rigorous quality check process in place to make
                    sure whatever we deliver is up to the expectations of our
                    clients.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">5</div>
                  <h3>Seamless Customer Support</h3>
                  <p>
                    We have a customer support team that works around the clock
                    for flawless communication with our proposed and existing
                    clients.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image">
              <img src={howitsWork} alt="choose" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
