import React from "react";
import { Link } from "gatsby";
import service3 from "../../assets/images/services/service3.png";
import starIcon from "../../assets/images/zest/small/about-us.svg";
import shape2 from "../../assets/images/our-mission/our-mission-shape2.png";
import shape1 from "../../assets/images/zest/background/vector-shape6.png";

const AboutArea = () => {
  return (
    <section className="about-area ptb-50 bg-fafafb">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <img src={service3} alt="about" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <span className="sub-title2">
                  <img src={starIcon} alt="about" />
                  <span>About Us</span>
                </span>
                <h2>We Are Dynamic Team Of Digital Marketing Agency</h2>
                <p>
                  At <b>Zestechno</b>, you can find comprehensive digital
                  marketing services which will help you in the overall growth
                  of your organization with better sales and elevated
                  reputation.
                </p>

                <ul className="about-list mb-3">
                  <li>
                    <i className="flaticon-tick"></i>
                    Digital Marketing
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Inbound Marketing
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    App & Web Development
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    UI/UX Design
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Strategy & Consulting
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Branding
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Publishing
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Content Marketing
                  </li>
                </ul>

                <p>
                  We design and develop digital experiences for businesses
                  Digitize your business with remarkable web design, custom app
                  development, and ROI-driven marketing.
                </p>

                {/* <Link to="/about-us" className="default-btn">
                  <i className="flaticon-right"></i>
                  More About Us
                  <span></span>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape1} alt="about" />
      </div>
    </section>
  );
};

export default AboutArea;
