import React from "react";
import { Link } from "gatsby";

import launch from "../../assets/images/zest/feature slides/launch.svg";
import design from "../../assets/images/zest/feature slides/design.svg";
import development from "../../assets/images/zest/feature slides/development.svg";
import planning from "../../assets/images/zest/feature slides/planning.svg";
import testing from "../../assets/images/zest/feature slides/testing.svg";
import monitoring from "../../assets/images/zest/feature slides/monitoring.svg";
import Loadable from "@loadable/component";
const OwlCarousel = Loadable(() => import("react-owl-carousel3"));

const options = {
  loop: true,
  nav: true,
  dots: true,
  autoplayHoverPause: true,
  autoplay: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  },
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
};

const FeaturedServicesSlide = () => {
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    setDisplay(true);
  }, []);

  return (
    <section className="featured-services-area ptb-50">
      <div className="container">
        <div className="section-title">
          <h2>As entrepreneurs, we like new challenges.</h2>
          <p>
            We like the energy of small units – of people who want to conquer
            the world. We know that an idea may be small today, but tomorrow it
            could put everything else in the shade.
          </p>
          <h3 className="mt-5">Our Growth-Driving Approach</h3>
        </div>
      </div>
      <div className="case-study-area container stepCarousel">
        {display ? (
          <OwlCarousel
            className="case-study-slides owl-carousel owl-theme"
            {...options}
          >
            <div className="item">
              <div className="single-featured-services-box">
                <div className="icon">
                  <img src={planning} alt="planning" />
                </div>
                <h3>
                  {/* <Link to="/service-details">1. PLANNING</Link> */}
                  1. PLANNING
                </h3>
                <p>
                  We analyze your vision and find out everything about your
                  goals, product, industry, audience, and competitors. Then we
                  translate our learnings into product specification,
                  wireframes, and other project documents.
                </p>
              </div>
            </div>

            <div className="item">
              <div className="single-featured-services-box">
                <div className="icon">
                  <img src={design} alt="DESIGN" />
                </div>
                <h3>
                  {/* <Link to="/service-details">2. DESIGN</Link> */}
                  2. DESIGN
                </h3>
                <p>
                  Based on the app strategy we created during the research
                  phase, we prepare the interactive prototype that responds to
                  user interactions. We then design the user interface of your
                  product.
                </p>
              </div>
            </div>

            <div className="item">
              <div className="single-featured-services-box">
                <div className="icon">
                  <img src={development} alt="Development" />
                </div>
                <h3>
                  {/* <Link to="/service-details">3. DEVELOPMENT</Link> */}
                  3. DEVELOPMENT
                </h3>
                <p>
                  We follow agile development methodologies and master the
                  latest technologies for iOS and Android to provide an
                  efficient and effective collaboration.
                </p>
              </div>
            </div>

            <div className="item">
              <div className="single-featured-services-box">
                <div className="icon">
                  <img src={testing} alt="Testing" />
                </div>
                <h3>
                  {/* <Link to="/service-details">4. TESTING</Link> */}
                  4. TESTING
                </h3>
                <p>
                  We thoroughly test the performance of every project we take on
                  to ensure its responsiveness, user-friendliness, and
                  bulletproof security.
                </p>
              </div>
            </div>

            <div className="item">
              <div className="single-featured-services-box">
                <div className="icon">
                  <img src={monitoring} alt="Monitoring" />
                </div>
                <h3>
                  {/* <Link to="/service-details">5. Monitoring</Link> */}
                  5. Monitoring
                </h3>
                <p>
                  Once a project plan, including an evaluation plan is ready, it
                  is important to set out how you will keep track of your
                  project’s developments and achievements.
                </p>
              </div>
            </div>

            <div className="item">
              <div className="single-featured-services-box">
                <div className="icon">
                  <img src={launch} alt="Launch & Support" />
                </div>
                <h3>
                  {/* <Link to="/service-details">5. LAUNCH & SUPPORT</Link> */}
                  6. LAUNCH & SUPPORT
                </h3>
                <p>
                  You can count on our post-release support because we aim for
                  long-term success. We are constantly tracking the market
                  changes and continue improving our apps accordingly.
                </p>
              </div>
            </div>
          </OwlCarousel>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default FeaturedServicesSlide;
