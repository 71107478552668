import React from "react";
import { Link } from "gatsby";

const FeaturedServices = () => {
  return (
    <section className="featured-services-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>As entrepreneurs, we like new challenges.</h2>
          <p>
            We like the energy of small units – of people who want to conquer
            the world. We know that an idea may be small today, but tomorrow it
            could put everything else in the shade.
          </p>
          <h3 className="mt-5">Our Growth-Driving Approach</h3>
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-featured-services-box">
              <div className="icon">
                <i className="flaticon-analysis"></i>
              </div>
              <h3>
                <Link to="/service-details">PLANNING</Link>
              </h3>
              <p>
                We analyze your vision and find out everything about your goals,
                product, industry, audience, and competitors. Then we translate
                our learnings into product specification, wireframes, and other
                project documents.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-featured-services-box">
              <div className="icon">
                <i className="flaticon-structure"></i>
              </div>
              <h3>
                <Link to="/service-details">DESIGN</Link>
              </h3>
              <p>
                Based on the app strategy we created during the research phase,
                we prepare the interactive prototype that responds to user
                interactions. We then design the user interface of your product.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
            <div className="single-featured-services-box">
              <div className="icon">
                <i className="flaticon-idea"></i>
              </div>
              <h3>
                <Link to="/service-details">DEVELOPMENT</Link>
              </h3>
              <p>
                We follow agile development methodologies and master the latest
                technologies for iOS and Android to provide an efficient and
                effective collaboration.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-featured-services-box">
              <div className="icon">
                <i className="flaticon-analysis"></i>
              </div>
              <h3>
                <Link to="/service-details">TESTING</Link>
              </h3>
              <p>
                We thoroughly test the performance of every project we take on
                to ensure its responsiveness, user-friendliness, and bulletproof
                security.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-featured-services-box">
              <div className="icon">
                <i className="flaticon-structure"></i>
              </div>
              <h3>
                <Link to="/service-details">LAUNCH & SUPPORT</Link>
              </h3>
              <p>
                You can count on our post-release support because we aim for
                long-term success. We are constantly tracking the market changes
                and continue improving our apps accordingly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedServices;
