import React from "react";
import { Link } from "gatsby";
import Branding from "../../assets/images/zest/our services/Branding.svg";
import App from "../../assets/images/zest/our services/App_and_Web_Development.svg";
import Content from "../../assets/images/zest/our services/Content_Marketing.svg";
import Design from "../../assets/images/zest/our services/Design_Services.svg";
import Digital from "../../assets/images/zest/our services/Digital_Marketing.svg";
import Inbound from "../../assets/images/zest/our services/Inbound_Marketing.svg";
import Publishing from "../../assets/images/zest/our services/Publishing_Services.svg";
import Strategy from "../../assets/images/zest/our services/Strategy_and_Consulting.svg";
import UI from "../../assets/images/zest/our services/UI_and_UX_Design.svg";

import serviceShape4 from "../../assets/images/zest/background/service-shape4.png";

const OurServices = () => {
  return (
    <section className="services-area ptb-50">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            At <b>Zestechno</b>, we offer a number of different internet
            marketing services to our clients. Click on the service you require
            to learn more.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={Digital} alt="Digital Marketing" />
              </div>
              <h3>
                {/* <Link to="/service-details">Digital Marketing</Link> */}
                Digital Marketing
              </h3>
              <p>
                Zestechno has helped many companies across to grow their
                businesses online by providing them with cutting-edge internet
                strategies and solutions.
              </p>
              {/* <Link to="/service-details" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link> */}
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={Inbound} alt="Inbound Marketing" />
              </div>
              <h3>
                {/* <Link to="/service-details">Inbound Marketing</Link> */}
                Inbound Marketing
              </h3>
              <p>
                Boost visibility, enhance the brand position and generate
                relevant leads through inbound marketing managed campaigns.
              </p>
              {/* <Link to="/service-details" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link> */}
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item ">
              <div className="icon">
                <img src={App} alt="App & Web Development" />
              </div>
              <h3>
                {/* <Link to="/service-details">App & Web Development</Link> */}
                App & Web Development
              </h3>
              <p>
                Investing time and effort into a responsive website that turns
                your online business into a market leader, will detach the great
                competition and increase the probability of exploiting a wider
                marketplace.
              </p>
              {/* <Link to="/service-details" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link> */}
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={UI} alt="UI/UX Design" />
              </div>
              <h3>
                {/* <Link to="/service-details">UI/UX Design</Link> */}
                UI/UX Design
              </h3>
              <p>
                For any custom developed project, a professional UX/UI design is
                the essential stage to make a product successful, especially
                when it comes to intuitive user experience.
              </p>
              {/* <Link to="/service-details" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link> */}
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={Branding} alt="Branding" />
              </div>
              <h3>
                {/* <Link to="/service-details">Branding</Link> */}
                Branding
              </h3>
              <p>
                Brand architecture is a challenge that comes with success. Say
                you create a new product line, a new profit centre or a new
                service. Better yet, you grow or acquire a new business.
              </p>
              {/* <Link to="/service-details" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link> */}
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={Strategy} alt="Strategy & Consulting" />
              </div>
              <h3>
                {/* <Link to="/service-details">Strategy & Consulting</Link> */}
                Strategy & Consulting
              </h3>
              <p>
                We provide The Best IT Staffing Services, Brand Strategy
                Services, Business Consulting Services, Content Marketing
                Services in Chennai. Gain the Focus and Frameworks that will
                take your business to the Next Level.
              </p>
              {/* <Link to="/service-details" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link> */}
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={Publishing} alt="Publishing Services" />
              </div>
              <h3>
                {/* <Link to="/service-details">Publishing Services</Link> */}
                Publishing Services
              </h3>
              <p>
                We work together with customers brainstorming new ideas for
                initiating projects, its stagewise execution, and ensuring
                on-time delivery to publishers employing innovative strategies
                with a focus on results.
              </p>
              {/* <Link to="/service-details" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link> */}
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={Content} alt="Content Marketing" />
              </div>
              <h3>
                {/* <Link to="/service-details">Content Marketing</Link> */}
                Content Marketing
              </h3>
              <p>
                Boost your digital presence with creative pieces that generate
                backlinks & drive traffic to your site. We can plan and
                implement creative content campaigns, new websites, digital
                promotion strategies, and cross-channel marketing for your
                brand.
              </p>
              {/* <Link to="/service-details" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link> */}
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={Design} alt="Design Services" />
              </div>
              <h3>
                {/* <Link to="/service-details">Design Services</Link> */}
                Design Services
              </h3>
              <p>
                We can create possible solutions for you which confirms the
                branding that you want for your business. We extensively use a
                variety of the latest creative tools in creating graphic designs
                for our valuable clients.
              </p>
              {/* <Link to="/service-details" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link> */}
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;
