import React from "react";
import partner1 from "../../assets/images/zest/clients/1a.png";
import partner2 from "../../assets/images/zest/clients/411staff-a.png";
import partner3 from "../../assets/images/zest/clients/abaca.png";
import partner4 from "../../assets/images/zest/clients/lotus-beauty.png";
import partner5 from "../../assets/images/zest/clients/modeling_studio.png";
import partner6 from "../../assets/images/zest/clients/mpl.png";
import partner7 from "../../assets/images/zest/clients/M-Studios.png";
import partner8 from "../../assets/images/zest/clients/namma-1.png";
import partner9 from "../../assets/images/zest/clients/NRI-care.png";
import partner10 from "../../assets/images/zest/clients/srusthi.png";
import partner11 from "../../assets/images/zest/clients/svm-1.png";
import partner12 from "../../assets/images/zest/clients/Talents-hill.png";
import partner13 from "../../assets/images/zest/clients/the_big_biriyani.png";
import partner14 from "../../assets/images/zest/clients/United.png";

import Loadable from "@loadable/component";
const OwlCarousel = Loadable(() => import("react-owl-carousel3"));

const Partner = () => {
  const options = {
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
    navText: [
      "<i class='flaticon-left-1'></i>",
      "<i class='flaticon-right-1'></i>",
    ],
  };

  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    setDisplay(true);
  }, []);

  let partners = [
    partner1,
    partner2,
    partner3,
    partner4,
    partner5,
    partner6,
    partner7,
    partner8,
    partner9,
    partner10,
    partner11,
    partner12,
    partner13,
    partner14,
  ];

  return (
    <div className="partner-area ptb-50 bg-fafafb">
      <div className="container">
        <h1 className="text-center mb-5">Our Clients</h1>
        {display ? (
          <OwlCarousel
            className="case-study-slides owl-carousel owl-theme"
            {...options}
          >
            {partners.map((slideItem, index) => (
              <div className="item single-partner-item" key={index}>
                <img src={slideItem} alt={`partner-${index}`} />
              </div>
            ))}
          </OwlCarousel>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Partner;
