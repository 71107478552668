import React from "react";
import user1 from "../../assets/images/zest/testimonials/t-1.png";
import user2 from "../../assets/images/zest/testimonials/t-2.png";
import user3 from "../../assets/images/zest/testimonials/t-3.png";
import user4 from "../../assets/images/zest/testimonials/t-4.png";
import shape9 from "../../assets/images/shape/vector-shape9.png";
import shape10 from "../../assets/images/shape/vector-shape10.png";
import Loadable from "@loadable/component";
const OwlCarousel = Loadable(() => import("react-owl-carousel3"));

const options = {
  loop: true,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: false,
  margin: 30,
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    992: {
      items: 2,
    },
  },
};

const Testimonials = () => {
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    setDisplay(true);
  }, []);

  return (
    <section className="testimonials-area bg-23173a pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <h2>What Clients Say About Us</h2>
          <p>
            Zestechno clients and their results are the best proof that our
            methodologies work. But we’ve also received numerous awards and
            praise from the media and our peers.
          </p>
        </div>

        {display ? (
          <OwlCarousel
            className="testimonials-slides owl-carousel owl-theme"
            {...options}
          >
            <div className="single-testimonials-box">
              <img src={user1} className="shadow-sm" alt="testimonials" />
              <p>
                Zestechno has been a tremendous asset to us in growing our
                business. Not only does he possess the latest professional
                knowledge, he also operates with high integrity. He is extremely
                reliable. If he promises something, he will deliver (no ifs and
                buts.) It is wonderful to find someone I can depend on.
              </p>

              <div className="client-info">
                <div className="title">
                  <h3>Sulochana</h3>
                  <span>CEO - Lotus Beauty Parlour</span>
                </div>
              </div>
            </div>

            <div className="single-testimonials-box">
              <img src={user2} className="shadow-sm" alt="testimonials" />
              <p>
                We have been using Zestechno's services for close to a year now.
                We have seen quantifiable improvement in terms of website
                traffic, keyword rankings and website speed.
              </p>

              <div className="client-info">
                <div className="title">
                  <h3>Ashwin Kumar</h3>
                  <span>CEO - Surya</span>
                </div>
              </div>
            </div>

            <div className="single-testimonials-box">
              <img src={user3} className="shadow-sm" alt="testimonials" />
              <p>
                Zestechno delivered quality work on a very tight deadline. I am
                amazed at their commitment to getting the work done. I recommend
                them as an IT partner without hesitation.
              </p>

              <div className="client-info">
                <div className="title">
                  <h3>Kat</h3>
                  <span>CEO - 411STAFF</span>
                </div>
              </div>
            </div>

            <div className="single-testimonials-box">
              <img src={user4} className="shadow-sm" alt="testimonials" />
              <p>
                As a businessman, I had the dream to make my business a brand.
                In this search, I meet with the Zestechno team and believe me
                they were that amazing team who just give me the satisfaction.
              </p>

              <div className="client-info">
                <div className="title">
                  <h3>Kannan</h3>
                  <span>CEO - SB BUILDING PRODUCTS</span>
                </div>
              </div>
            </div>
          </OwlCarousel>
        ) : (
          ""
        )}
      </div>

      <div className="vector-shape9">
        <img src={shape9} alt="testimonials" />
      </div>
      <div className="vector-shape10">
        <img src={shape10} alt="testimonials" />
      </div>
    </section>
  );
};

export default Testimonials;
